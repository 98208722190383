import { default as $ } from 'jquery';

$(function(){
    var minimized_elements = $('div.minimize');
    var minimize_character_count = 415;

    minimized_elements.each(function(){    
        var t = $(this).text();  
              
        if(t.length < minimize_character_count ) return;

        $(this).html(
            t.slice(0,minimize_character_count )+'<span>... </span><a href="#" class="more">Show more</a>'+
            '<span class="content" style="display:none;">'+ t.slice(minimize_character_count ,t.length)+' <a href="#" class="less">Show less</a></span>'
        );

    }); 

    $('a.more', minimized_elements).click(function(event){
        event.preventDefault();
        $(this).hide().prev().hide();
        $(this).next().show();
        $('.m-team-card').removeClass('h-100');        
    });

    $('a.less', minimized_elements).click(function(event){
        event.preventDefault();
        $(this).parent().hide().prev().show().prev().show();
        $('.m-team-card').addClass('h-100');        
    });
});