import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-carousel-list.-isCarousel').slick({
        dots: false,
        arrows: true,
        prevArrow: '<button aria-label="Previous" type="button" class="slick-prev me-5"><i aria-hidden="true" class="fa-solid fa-arrow-left-long c-pink"></i></button>',
        nextArrow: '<button aria-label="Next" aria-label="Next" type="button" class="slick-next"><i aria-hidden="true" class="fa-solid fa-arrow-right-long c-pink"></i></button>',
        appendArrows: $('.m-carousel-list-arrows'),
        swipeToSlide: true,
        centerMode: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        initialSlide: 0,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2.2,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: "120px",
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: "80px",
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: "50px",
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: "0px",
                }
            },
        ]
    });

    if ($('.m-carousel-list').length) {
        // Check if the container is in view when the page loads
        startAutoplayWhenInView();
    
        // Listen for scroll events to check if the container comes into view
        $(window).scroll(function () {
            startAutoplayWhenInView();  
        });
    };
});

var autoplayStarted = false;

// Function to start autoplay when container is in view
function startAutoplayWhenInView() {
    var container = $('.m-carousel-list');

    if (!container.length || autoplayStarted) {
        return; // Exit the function if the container is not found
    }

    var containerTop = container.offset().top;
    var containerBottom = containerTop + container.height();
    var windowTop = $(window).scrollTop();
    var windowBottom = windowTop + $(window).height();

    if (containerBottom > windowTop && containerTop < windowBottom) {
        // The container is in view
        container.slick('slickPlay');
        autoplayStarted = true;
    }
}