import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    $("#searchButton").on("click", function(){
        $("#searchform").toggleClass("-show");
    });

    var header = $("#header");
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 100) {
            header.addClass("-dark");
            $('.header-hamburger-bars').addClass('-white');
        } else {
            header.removeClass("-dark");
            $('.header-hamburger-bars').removeClass('-white');
        }
    });    

    $('#mobileButton').on('click', function(){
        $('#header').toggleClass('-bg');
        $('#body').toggleClass('-no-scroll');
        $('.header-hamburger-bars').toggleClass('-active');
        $('#headerMobile').toggle();
    });
    
    $('.menu-item-has-children').on('click', function(){
        $(this).toggleClass('-show');
    });

    // Hide header notice cookie
    // $('#headerNotice').show();

    // if (getCookie('noticeHidden') === 'true') {
    //     $('#headerNotice').hide();
    // };

    // $('#closeNotice').on('click', function() {
    //     $('#headerNotice').hide();

    //     setCookie('noticeHidden', 'true', 30);
    // });

    // // Set cookie
    // function setCookie(name, value, days) {
    //     var expires = '';
    //     if (days) {
    //         var date = new Date();
    //         date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    //         expires = '; expires=' + date.toUTCString();
    //     }
    //     document.cookie = name + '=' + value + expires + '; path=/';
    // }

    // // Get cookie
    // function getCookie(name) {
    //     var nameEQ = name + '=';
    //     var ca = document.cookie.split(';');
    //     for (var i = 0; i < ca.length; i++) {
    //         var c = ca[i];
    //         while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    //         if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    //     }
    //     return null;
    // }
});

