import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-media-gallery-list.-isCarousel').slick({
        dots: false,
        arrows: false,
        swipeToSlide: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        initialSlide: 0,
        controls: true,
        autoplay: true,
        autoplaySpeed: 6000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 0,
    });
});