import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-quotes-list.-isCarousel').slick({
        dots: true,
        arrows: false,
        customPaging: function(slider, i) {
            return '<span class="dots"></span>';
        },
        appendDots: $('.m-quotes-dots'),
        draggable: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });
});