import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-alumni-alt-list.-isCarousel').slick({
        dots: false,
        arrows: false,
        draggable: true,
        centerMode: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        initialSlide: 0,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 4.5,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3.5,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: "100px",
                }
            },
            {
                breakpoint: 520,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: "70px",
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: "30px",
                }
            },
        ]
    });
});