// This file contains all parachute imports, e.g:
import './accordion/accordion';
import './animation/animation';
import './mmenu/mmenu';
import './header/header';
import './carousel/carousel';
import './splitting/splitting';
import './carousel/logos';
import './card/card';
import './carousel/related';
import './banner/banner';
import './news/news';
import './quotes/quotes';
import './alumni/alumni';
import './alumni/alumni-carousel';
import './carousel/case-study';
import './breadcrumbs/breadcrumbs';
import './team/team';
import './carousel/media-text';
import './readmore/readmore';

// Publicly accessible methods for this build, useful for callbacks in script tags etc.
// Try and keep most things inside this webpack bundle though
import { publicExports } from './public-exports';
window._Parachute = publicExports || {};
