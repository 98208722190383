import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    $(".m-team-more-link").on("click", function() {
        $(this).closest(".m-team-text").find(".m-team-more-content").slideToggle();
        $(this).toggleClass('active');
        if($(this).hasClass('active')){
            $(this).html('Read Less<i class="fa-solid fa-chevron-up ms-3 c-white"></i>');
        } else {
            $(this).html('Read More<i class="fa-solid fa-chevron-down ms-3 c-white"></i>');
        }
    });
});