import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    $('#searchAlumni').on('click', function(){
        alumniFilter();
    });

    $('#search-alumni').on('keydown', function(event) {
        if (event.keyCode == 13) {
            alumniFilter();
        }
    });
});

function alumniFilter() {
    var date = $('#date').find(":selected").val();
    var text = $('#search-alumni').val().toLowerCase();
    var anySlidesShown = false;

    $('.m-people-slide').each(function(i, obj) {
        var slide_date = $(this).data('date');
        var slide_text = $(this).text().toLowerCase();
        var slide_number = $(this).data('number');

        var matchDate = (date === "all" || slide_date == date);
        var matchText = (text === "" || slide_text.includes(text));

        var filterAll = (date === 'all');
        var filterText = (text === '');

        if (matchDate && matchText) {
            $(this).show();
            anySlidesShown = true;
            $('.m-people-heading').hide();
            $('.m-people-heading-'+slide_number).show();
        } else {
            $(this).hide();
        }

        if (filterAll && filterText) {
            $('.m-people-heading').show();
        }
    });

    if (!anySlidesShown) {
        $('#noresults').show();
        $('.m-people-heading').hide();
    } else {
        $('#noresults').hide();
    }
}