import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    $('.m-card-icon:first').addClass('-active');

    $('.m-card-icon').on('click', function(){
        $('.m-card-icon').removeClass('-active')
        $(this).addClass('-active');
        var section = $(this).attr('id');

        $(".m-card-img").each(function() {
            if($(this).data('icon') === section) {
                $('.m-card-img').hide();
                $(this).show();
            };
        });

        $(".m-card-content").each(function() {
            if($(this).data('icon') === section) {
                $('.m-card-content').hide();
                $(this).show();
            };
        });

    });
});