import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-banner-carousel.-isCarousel').slick({
        dots: true,
        arrows: true,
        prevArrow: '<button aria-label="Previous" type="button" class="slick-prev"><i aria-hidden="true" class="fa-solid fa-caret-left c-white"></i></button>',
        nextArrow: '<button aria-label="Next" aria-label="Next" type="button" class="slick-next"><i aria-hidden="true" class="fa-solid fa-caret-right c-white"></i></button>',
        customPaging: function(slider, i) {
            return '<span class="dots"></span>';
        },
        appendArrows: $('.m-banner-controls'),
        appendDots: $('.m-banner-controls'),
        draggable: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        controls: true,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });
});