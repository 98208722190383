import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-news-block-list.-isCarousel').slick({
        dots: false,
        arrows: false,
        swipeToSlide: true,
        centerMode: false,
        slidesToShow: 3.5,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1.5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1.8,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1.5,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1.2,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });

    $('.m-news-block-list.-isCarousel').on('wheel', (function(e) {
        e.preventDefault();
        if (e.originalEvent.deltaY < 0) {
          $(this).slick('slickPrev');
        } else {
          $(this).slick('slickNext');
        }
    }));
});

var autoplayStarted = false;

// Function to start autoplay when container is in view
function startAutoplayWhenInView() {
    var container = $('.m-news-block-list');

    if (!container.length || autoplayStarted) {
        return; // Exit the function if the container is not found
    }

    var containerTop = container.offset().top;
    var containerBottom = containerTop + container.height();
    var windowTop = $(window).scrollTop();
    var windowBottom = windowTop + $(window).height();

    if (containerBottom > windowTop && containerTop < windowBottom) {
        // The container is in view
        container.slick('slickPlay');
        autoplayStarted = true;
    }
}

if ($('.m-news-block-list').length) {
    // Check if the container is in view when the page loads
    startAutoplayWhenInView();

    // Listen for scroll events to check if the container comes into view
    $(window).scroll(function () {
        startAutoplayWhenInView();  
    });
};